import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Post from "../components/Post";
import { makeApiCall } from "../utils/Apicall";
import { API_URL } from "../constants";

const Myposts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const url = `${API_URL}/feed/${user._id}`;
    makeApiCall(url, "get")
      .then((res) => {
        console.log(res);
        setPosts(res?.posts ? res.posts : []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.5rem",
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "1rem",
        }}
      >
        {posts.map((post) => (
          <Post key={post._id} post={post} owns={true} />
        ))}

        {loading && <CircularProgress />}
      </Box>
    </Box>
  );
};

export default Myposts;
