import React, { useState } from "react";
import { Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link, Element } from "react-scroll";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Page3 = () => {
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    color += "1a";
    return color;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        color: "#FFFFFF",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "auto",
          position: "relative",
          color: "#FFFFFF",
          padding: "2.5%",
          width: {
            xs: "95%",
            sm: "95%",
            md: "95%",
            lg: "65%",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#242B2E",
            borderRadius: "25px",
            color: "#FFFFFF",
            height: "550px",
            padding: "1% 2.5% 2.5% 2.5%",
          }}
        >
          <Element name="About">
            <h1
              style={{
                textAlign: "center",
              }}
            >
              About
            </h1>
          </Element>
          <Box
            sx={{
              width: "95%",
              height: "85%",
              overflow: "auto",
              margin: "auto",
              bgcolor: "#758283",
              borderRadius: "25px",
              color: "#d3d3d3",
              padding: "0% 2.5%",
              "&::-webkit-scrollbar": {
                width: "0.6em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                backgroundColor: "#CAD5E2",
                borderRadius: "25px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#758283",
                borderRadius: "25px",
              },
              background: `radial-gradient(${getRandomColor()} 3px, transparent 4px), radial-gradient(${getRandomColor()} 3px, transparent 4px), linear-gradient(#000 4px, transparent 0), linear-gradient(45deg, transparent 74px, transparent 75px, ${getRandomColor()} 75px, ${getRandomColor()} 76px, transparent 77px, transparent 109px), linear-gradient(-45deg, transparent 75px, transparent 76px, ${getRandomColor()} 76px, ${getRandomColor()} 77px, transparent 78px, transparent 109px), #000`,
              backgroundSize:
                "109px 109px, 109px 109px, 100% 6px, 109px 109px, 109px 109px",
              backgroundPosition:
                "54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px",
            }}
          >
            <Box
              sx={{
                marginTop: "50px",
              }}
            >
              <Accordion
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#ffffff1f",
                  borderRadius: "15px 15px 0px 0px",
                  marginTop: "25px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#f0e68c",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#ffffff1f",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0e68c",
                      fontFamily: "comic neue",
                    }}
                  >
                    Background
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      textAlign: "justify",
                      fontSize: "1rem",
                      fontFamily: "comic neue",
                    }}
                  >
                    <p>
                      Deep learning has emerged as a powerful tool in artificial
                      intelligence, thanks to advancements in data science and
                      computing power, such as the widespread use of GPUs and
                      TPUs. Its applications are vast, including Automation,
                      Robotics, Natural Language Processing, Computer Vision,
                      Speech Recognition, Object Detection, Remote Sensing,
                      Language Modeling, and Drug Discovery. Deep learning has
                      achieved remarkable feats, surpassing world champions in
                      various games and becoming a highly sought-after skill in
                      the industry. By utilizing deep neural network
                      architectures with multiple layers, it enables data
                      representation with increasing abstraction and can be
                      applied to different learning scenarios, such as
                      supervised, unsupervised, and reinforcement learning.
                    </p>
                    <p>
                      The Workshop on Advances in Deep Learning and Applications
                      (WADLA) was established in 2021. It features a committee
                      of deep learning experts responsible for overseeing the
                      workshop's activities and finalizing the program schedule.
                      WADLA 1.0, held from February 22nd to 26th, 2021,
                      attracted 64 participants, including faculties, Ms/PhD
                      students, and researchers from esteemed institutions like
                      IITs, IIITs, NITs, and other premier organizations. The
                      workshop featured 20 speakers, including 10 from academia
                      and 6 from industry, with 2 international speakers from
                      Germany and Taiwan.
                    </p>
                    <p>
                      Building on the success of WADLA 1.0, WADLA 2.0 took place
                      from February 21st to 25th, 2022. This highly successful
                      workshop aimed to draw researchers, educators, and
                      industry professionals interested in deep learning. Dr.
                      Manish Gupta, Director of Google Research India,
                      inaugurated the event, and Shri Vishnu Chandra, Group Head
                      of the National Informatics Center of the Government of
                      India, delivered the closing address. With 37 speakers,
                      including IIT faculties, industry experts, and government
                      officials, the workshop provided a solid foundation in the
                      fundamentals and advancements of deep learning, showcasing
                      practical applications across various domains. The event
                      fostered interactions between academia and industry,
                      offering participants the chance to demonstrate deep
                      learning capabilities through industry-led hands-on
                      sessions. Attendees included researchers and faculties
                      from around the world, as well as renowned Indian
                      institutions like IITs, NITs, IIITs, IISERs, and central
                      and state universities. Sponsored by DST, the workshop
                      received an overwhelming response, attracting 113
                      participants.
                    </p>
                    <p>Following the success of WADLA 2.0, WADLA 3.0 was scheduled from December 11th to 16th, 2023, and inaugurated with esteemed guests and participants. Led by Prof. Ashutosh Singh, Director of IIIT Bhopal, and Shri M. Balasubramaniam, CEO of StratInfinity Inc., the event aimed to foster collaboration between academia and industry in the realm of deep learning. Established in 2021, WADLA aims to provide quality content and promote interactions between academia and industry. Its latest iteration, WADLA 3.0, continued this mission by offering practical sessions to enhance understanding of deep learning fundamentals and advancements. The workshop served as a platform for industry experts to showcase deep learning models through hands-on sessions. WADLA 3.0 targeted research students, faculty members, educators, and industry experts, offering insights applicable to their studies, exercises, and professional endeavors. Topics such as Large Language Models (LLM), Generative AI, and ChatGPT were selected based on industry demands, with the overarching goal of leveraging cutting-edge deep learning methods for success. This year's workshop boasted 52 sessions featuring 39 distinguished speakers from renowned institutions worldwide, including IITs, NITs, IIITs, and leading industry players like Microsoft, Google, Intel, LinkedIn, Nvidia, Amazon, ISRO, Adobe, Meesho, Samsung, and 24/7 AI. The event garnered significant interest, with 130 participants, including researchers and faculty members from prestigious institutes. Overall, WADLA 3.0 promised to be a valuable platform for knowledge exchange and collaboration in the rapidly evolving field of deep learning, bridging the gap between academia and industry to drive innovation and progress.</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#ffffff1f",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#f0e68c",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#ffffff1f",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0e68c",
                      fontFamily: "comic neue",
                    }}
                  >
                    About WADLA 4.0
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      textAlign: "justify",
                      fontSize: "1rem",
                      fontFamily: "comic neue",
                    }}
                  >
                    <p>
                      Mark your calendars for December 9th to 14th, 2024, as WADLA 4.0 gears up to revolutionize the landscape of deep learning. Embracing the spirit of innovation and education, WADLA 4.0 promises a transformative learning journey for researchers, educators, and industry professionals alike.
                    </p>
                    
                    <p>
                      What to Expect: 
                    </p>

                    <p>
                      Cutting-Edge Content: Explore the forefront of deep learning with an updated program schedule featuring advanced architectures, algorithms, and applications. Stay ahead of the curve with insights into the latest research breakthroughs and industry demands.
                    </p>

                    <p>
                      Expert Curation: Our esteemed committee, in collaboration with leading experts and practitioners, will craft a comprehensive agenda. Expect deep dives into emerging architectures, novel optimization algorithms, ethical considerations, and specialized applications across diverse domains.
                    </p>
                    <p>
                      Practical Hands-On Sessions: Gain invaluable practical experience through hands-on sessions designed to bridge theory and application. Dive into advanced computer vision, natural language processing, and other specialized domains, empowering yourself to tackle real-world challenges with confidence.
                    </p>
                    <p>
                      Why Attend: 
                    </p>
                    <p>
                      Stay Ahead: Stay abreast of the latest advancements and trends shaping the future of deep learning.
                    </p>
                    <p>
                      Network: Forge valuable connections with fellow researchers, educators, and industry professionals, fostering collaborations and knowledge exchange.
                    </p>
                    <p>
                      Skill Enhancement: Equip yourself with the skills and knowledge needed to excel in your research, teaching, and industry endeavors.
                    </p>
                    <p>
                      Join us at WADLA 4.0 as we embark on a journey to unlock the full potential of deep learning and drive innovation forward.
                    </p>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#ffffff1f",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#f0e68c",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#ffffff1f",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0e68c",
                      fontFamily: "comic neue",
                    }}
                  >
                    Topics to be Covered
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      textAlign: "justify",
                      fontSize: "1rem",
                      fontFamily: "comic neue",
                    }}
                  >
                    <p>
                      WADLA 4.0 will cover a wide range of topics related to
                      deep learning
                    </p>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          1. Pre-Deep Learning Essentials
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
                            <li>
                            Activation Functions (e.g., Swish, Mish)

                            </li>
                            <li>
                            Loss Functions (e.g., Focal Loss, Lovász-Softmax)
                            </li>
                            <li>Differentiable Programming</li>
                            <li>
                              Optimization Algorithms (e.g., Ranger, RAdam)
                            </li>
                            <li>
                              Regularization Techniques (e.g., Cutout, Mixup)
                            </li>
                            <li>
                              Data Augmentation Strategies (e.g., RandAugment, AutoAugment)
                            </li>
                            <li>
                              Evaluation Metrics  (e.g., Matthews Correlation Coefficient, Area Under the ROC Curve)
                            </li>
                          </ul>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          2. Foundations of Deep Learning
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
                            <li>
                              Transformer-based Models (e.g., BERT, T5)
                            </li>
                            <li>
                              Variants of Convolutional Neural Networks (e.g., EfficientNet, RegNet)
                             </li>
  <li>Sequence Models (e.g., Transformer-XL, Longformer)</li>
  <li>
    Attention Mechanisms (e.g., Performer, Linformer)
  </li>
  <li>
    Unsupervised Learning (e.g., Contrastive Learning, SimCLR)
  </li>
  <li>Graph Neural Networks (e.g., Gated Graph Neural Networks, GraphSAGE)</li>
  <li>Reinforcement Learning with Model-based Approaches</li>
  <li>
    Generative Adversarial Networks (GANs) and Variants (e.g., StyleGAN2, BigGAN)
  </li>
  <li>Self-Supervised Learning with Contrastive Methods</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          3. Advances in Deep Learning
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
  <li>Continual Learning and Lifelong Learning</li>
  <li>Few-Shot Learning with Meta-Learning Approaches</li>
  <li>Self-Supervised Learning for Speech and Audio</li>
  <li>Privacy-Preserving Machine Learning</li>
  <li>Multi-Modal Transformers for Vision and Language Tasks</li>
  <li>Adversarial Robustness and Adversarial Training</li>
  <li>Interpretability and Explainability in Deep Learning</li>
  <li>Neurosymbolic AI and Integration of Knowledge Graphs</li>
  <li>Quantum Machine Learning and Quantum Neural Networks</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          4. Recent Trends in Deep Learning:
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
  <li>Zero-Shot and Few-Shot Learning for Vision and Language Tasks</li>
  <li>Large-Scale Pretraining for Vision and Speech Models</li>
  <li>Deep Learning for Personalized Healthcare and Medicine</li>
  <li>Federated Learning for Decentralized Data</li>
  <li>Edge AI and Federated Learning on Resource-Constrained Devices</li>
  <li>Self-Supervised Learning for Reinforcement Learning</li>
  <li>Differentiable Architecture Search for Neural Architecture Design</li>
  <li>Advanced Generative Models for Text and Code Generation</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          5. Deep Learning Applications:
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
  <li>Advanced Medical Imaging and Diagnosis</li>
  <li>Autonomous Vehicles with Multi-Modal Sensor Fusion</li>
  <li>Precision Agriculture and Crop Monitoring</li>
  <li>Earth Observation and Climate Change Analysis</li>
  <li>Drug Discovery and Protein Folding Prediction</li>
  <li>Emotion Recognition and Behavioral Analysis</li>
  <li>Human-Machine Collaboration in Robotics</li>
  <li>Augmented Reality and Virtual Reality Applications</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          6. Deep Learning Frameworks and Packages
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
  <li>PyTorch and PyTorch Lightning</li>
  <li>TensorFlow and TensorFlow Extended (TFX)</li>
  <li>JAX and Flax for High-Performance Computing</li>
  <li>Hugging Face Transformers for Natural Language Processing</li>
  <li>Fastai for Fast Experimentation and Research</li>
  <li>ONNX Runtime for Efficient Model Deployment</li>
  <li>Ray for Distributed Training and Hyperparameter Optimization</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          7. Hands-on Sessions on Advanced Topics
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
  <li>Neural Architecture Search with AutoML Techniques</li>
  <li>Generative Models for Creative Applications</li>
  <li>Reinforcement Learning in Real-World Simulations</li>
  <li>Large-Scale Language Model Fine-Tuning</li>
  <li>Advanced Techniques for Model Compression and Quantization</li>
  <li>Responsible AI and Ethical Considerations in Deep Learning</li>
  <li>Deploying Models with Containerization and Orchestration</li>
  <li>Advanced Hyperparameter Optimization Strategies</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          8. Trends in Deep Learning Research
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          <ul>
  <li>Adversarial Machine Learning Beyond Image Data</li>
  <li>Reinforcement Learning for Real-World Control Tasks</li>
  <li>Neuro-Evolutionary Algorithms for Complex Optimization</li>
  <li>Energy-Efficient Deep Learning Hardware and Algorithms</li>
  <li>Neural Architecture Search with Network Morphism</li>
  <li>Explainable AI for Black-Box Models</li>
  <li>Multi-Task Learning and Transfer Learning Strategies</li>
  <li>Deep Learning for Social Good and Environmental Sustainability</li>
</ul>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
              
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#ffffff1f",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#f0e68c",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#ffffff1f",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0e68c",
                      fontFamily: "comic neue",
                    }}
                  >
                    Intended Participants
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      textAlign: "justify",
                      fontSize: "1rem",
                      fontFamily: "comic neue",
                    }}
                  >
                    <p>
                      WADLA 3.0 aims to cater to the needs and interests of
                      various participants, including:
                    </p>
                    <ul>
                      <li>
                        Research Students (BTech with
                        Research/MS/PhD/Equivalent)
                      </li>
                      <li>Faculties and Educators</li>
                      <li>R & D and Industry Persons</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#ffffff1f",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#f0e68c",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#ffffff1f",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0e68c",
                      fontFamily: "comic neue",
                    }}
                  >
                    Goal of the workshop
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      textAlign: "justify",
                      fontSize: "1rem",
                      fontFamily: "comic neue",
                    }}
                  >
                    <p>
                    The workshop aims to:
                    </p>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Foundation Building
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          Understand key concepts and algorithms as a solid base for deep learning exploration.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Advanced Techniques
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                           Dive into cutting-edge advancements such as transformer architectures and reinforcement learning.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Practical Applications
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          {" "}
                          Apply deep learning to real-world problems in various sectors like healthcare and natural language processing.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Hands-on Experience
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          Gain practical skills through hands-on sessions with frameworks and tools.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Industry-Academia Interactions:
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                           Foster collaboration between academia and industry through sessions and discussions.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Trends and Research Directions
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                           Explore the latest trends and future directions in deep learning research.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Networking Opportunities
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          Connect with peers and experts for potential collaborations and career growth.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Personalized Learning Paths
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          Tailor learning to individual backgrounds and needs
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Access to Resources
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                          Get exclusive materials for continued learning beyond the workshop.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Certification
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                           Receive certification upon completion, enhancing professional profiles.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Mentorship and Guidance
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                           Receive guidance from leading experts in navigating complex topics.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Interactive Sessions
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                            Participate in Q&A panels and group discussions for deeper engagement.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#ffffff1f",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "#f0e68c",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          backgroundColor: "#ffffff1f",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#EF5354",
                            fontFamily: "comic neue",
                          }}
                        >
                          Real-World Problem Solving
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            textAlign: "justify",
                            fontSize: "1rem",
                            fontFamily: "comic neue",
                          }}
                        >
                           Apply learned concepts to real-world projects, enhancing problem-solving skills.

                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                  
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          color: "#FFFFFF",
          padding: "2.5%",
          width: {
            xs: "95%",
            sm: "95%",
            md: "95%",
            lg: "25%",
          },
          height: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#242B2E",
            color: "#FFFFFF",
            borderRadius: "25px",
            maxHeight: {
              lg: "860px",
              xs: "auto",
            },
            height: {
              lg: "96%",
            },
            textAlign: "center",
            padding: "2.5% 2.5% 1% 2.5%",
          }}
        >
          <Element name="Archive">
            <h1>Archive</h1>
          </Element>
          <Box
            sx={{
              height: "33%",
              overflow: "auto",
            }}
          >
            <h2
              style={{
                color: "#f9adc0",
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: "#f9adc0",
                }}
                href="https://third.wadla.in/"
                target="_blank"
              >
                Wadla 3.0 - 2023 {<LaunchIcon />}
              </a>
            </h2>
            <Box
              sx={{
                height: "93%",
                padding: "0 2.5%",
                overflowY: "auto",

                "&::-webkit-scrollbar": {
                  width: ".6em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  backgroundColor: "#CAD5E2",
                  borderRadius: "25px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#758283",
                  borderRadius: "25px",
                },
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "0.85rem",
                }}
              >
                WADLA 3.0 was scheduled from December 11th to 16th, 2023, and inaugurated with esteemed guests and participants. Led by Prof. Ashutosh Singh, Director of IIIT Bhopal, and Shri M. Balasubramaniam, CEO of StratInfinity Inc., the event aimed to foster collaboration between academia and industry in the realm of deep learning. Established in 2021, WADLA aims to provide quality content and promote interactions between academia and industry. Its latest iteration, WADLA 3.0, continued this mission by offering practical sessions to enhance understanding of deep learning fundamentals and advancements. The workshop served as a platform for industry experts to showcase deep learning models through hands-on sessions. WADLA 3.0 targeted research students, faculty members, educators, and industry experts, offering insights applicable to their studies, exercises, and professional endeavors. Topics such as Large Language Models (LLM), Generative AI, and ChatGPT were selected based on industry demands, with the overarching goal of leveraging cutting-edge deep learning methods for success. This year's workshop boasted 52 sessions featuring 39 distinguished speakers from renowned institutions worldwide, including IITs, NITs, IIITs, and leading industry players like Microsoft, Google, Intel, LinkedIn, Nvidia, Amazon, ISRO, Adobe, Meesho, Samsung, and 24/7 AI. The event garnered significant interest, with 130 participants, including researchers and faculty members from prestigious institutes. Overall, WADLA 3.0 promised to be a valuable platform for knowledge exchange and collaboration in the rapidly evolving field of deep learning, bridging the gap between academia and industry to drive innovation and progress.

              </p>
            </Box>
          </Box>
          <Box
            sx={{
              height: "33%",
              overflow: "auto",
            }}
          >
            <h2
              style={{
                color: "#f9adc0",
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: "#f9adc0",
                }}
                href="https://second.wadla.in/"
                target="_blank"
              >
                Wadla 2.0 - 2022 {<LaunchIcon />}
              </a>
            </h2>
            <Box
              sx={{
                height: "93%",
                padding: "0 2.5%",
                overflowY: "auto",

                "&::-webkit-scrollbar": {
                  width: ".6em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  backgroundColor: "#CAD5E2",
                  borderRadius: "25px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#758283",
                  borderRadius: "25px",
                },
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "0.85rem",
                }}
              >
                WADLA 2.0 was a highly successful workshop aimed at researchers,
                educators, and industry professionals interested in deep
                learning. The event was inaugurated by Dr. Manish Gupta,
                Director of Google Research India, and valedicted by Shri Vishnu
                Chandra, Group Head of the National Informatics Center of the
                Government of India. The workshop provided a strong foundation
                in the basics and advancements of deep learning, with practical
                applications across various domains. The workshop also
                facilitated academia-industry interactions through the
                participation of leaders from diverse fields and included
                industry-led hands-on sessions for participants to demonstrate
                deep learning capabilities. 37 speakers, including 15 IIT
                faculties, 13 industry experts, 2 from the Government of India,
                and 7 international speakers, were present at the event.
                Researchers and faculties from around the world, including the
                UK, Israel, South Korea, Mexico, Denmark, Morocco, and Sudan, as
                well as from IITs, NITs, IIITs, IISERs, and central and state
                universities, participated in the workshop. The event was
                sponsored by DST and received an overwhelming response with 113
                participants in attendance.
              </p>
            </Box>
          </Box>
          
          <Box
            sx={{
              height: "28%",
              overflow: "auto",
            }}
          >
            <h2
              style={{
                color: "#f9adc0",
              }}
            >
              {/* make default styles none */}
              <a
                href="https://sites.google.com/view/wadla2021"
                style={{
                  textDecoration: "none",
                  color: "#f9adc0",
                }}
                target="_blank"
              >
                Wadla 1.0 - 2021 {<LaunchIcon />}
              </a>
            </h2>
            <p
              style={{
                textAlign: "justify",
                height: "63%",
                overflowY: "hidden",
                fontSize: "0.85rem",
                padding: "0 2.5%",
              }}
            >
              The WADLA - 2021 workshop had 64 participants. These included
              faculties, Ms/PhD students and other researchers. All the
              participants were from premier institutions such as IITs, IIITs,
              NITs and other premier institutions. Importantly, there were 20
              speakers, of which 16 speakers were invited from outside IIIT Sri
              City. Of these 16 speakers, 10 of them were from academia while 6
              were from industry. Notably, there were 2 speakers from Germany
              and Taiwan. The workshop was held over 5 days; from 22 - 26 Feb
              2021.
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Page3;
