import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import { CircularProgress, InputBase, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { makeApiCall } from "../utils/Apicall";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL } from "../constants";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Post = ({ isComment, post, owns }) => {
  const [expanded, setExpanded] = useState(false);
  const [isTextShown, setIsTextShown] = useState(false);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [commentsloading, setCommentsLoading] = useState(false);

  const text = post?.content || "No content";
  const createdAt = new Date(post?.createdAt).toLocaleString();

  const getComments = async () => {
    const url = `${API_URL}/feed/${post._id}/comment`;
    makeApiCall(url, "get")
      .then((res) => {
        console.log(res);
        setComments(res?.comments ? res.comments : []);
      })
      .finally(() => {
        setCommentsLoading(false);
      });
  };

  const handleExpandClick = async () => {
    setExpanded(!expanded);
    if (!expanded) {
      setCommentsLoading(true);
      await getComments();
    }
  };

  const addComment = async () => {
    const url = `${API_URL}/feed/${post._id}/comment`;
    if (comment.length == 0) {
      alert("add content to comment");
      return;
    }
    setLoading(true);
    makeApiCall(url, "post", {
      content: comment,
    })
      .then((res) => {
        console.log(res);
      })
      .finally(async () => {
        setLoading(false);
        await getComments();
      });

    setComment("");
  };

  const deletePost = async () => {
    setDeleting(true);
    console.log(post.author._id);
    const url = `${API_URL}/feed/${post._id}/${post.author._id}/`;
    makeApiCall(url, "delete")
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setDeleting(false);
        window.location.reload();
      });
  };

  return deleting ? (
    <CircularProgress />
  ) : (
    <Card sx={{ width: 345, width: isComment ? "100%" : "345px" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }}>
            {post?.author?.name[0] || "A"}
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        {...(owns && {
          action: (
            <IconButton aria-label="settings" onClick={deletePost}>
              <DeleteIcon />
            </IconButton>
          ),
        })}
        title={post?.author?.name || "Anonymous"}
        subheader={createdAt || "No date"}
      />

      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: isTextShown ? undefined : 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {text.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Typography>
        {text.length > 100 && (
          <button
            onClick={() => setIsTextShown(!isTextShown)}
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "#3f51b5",
              cursor: "pointer",
              outline: "none",
              padding: "0",
              margin: "0",
              fontSize: "1rem",
            }}
          >
            {isTextShown ? "Show Less" : "Show More"}
          </button>
        )}
      </CardContent>
      {!isComment && (
        <>
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <ExpandMore
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show comments"
            >
              <Typography>Comments</Typography>
            </ExpandMore>
          </CardActions>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent
              sx={{
                backgroundColor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  justifyContent: "center",
                }}
              >
                <InputBase
                  placeholder="Add a comment"
                  multiline
                  rows={2}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "1rem",
                    padding: "0.5rem",
                    width: "100%",
                  }}
                  onChange={(e) => setComment(e.target.value)}
                />
                {loading ? (
                  <CircularProgress />
                ) : (
                  <IconButton aria-label="send" onClick={addComment}>
                    <SendIcon />
                  </IconButton>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  padding: "1rem",
                  width: "85%",
                  marginLeft: "7%",
                }}
              >
                {/* <Post isComment={true} />
                <Post isComment={true} /> */}
                {commentsloading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {comments.length === 0 ? (
                      <Typography
                        sx={{
                          color: "#0009",
                        }}
                      >
                        Be the first one to comment
                      </Typography>
                    ) : (
                      comments.map((comment) => (
                        <Post
                          key={comment._id}
                          isComment={true}
                          post={comment}
                        />
                      ))
                    )}
                  </>
                )}
              </Box>
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default Post;
