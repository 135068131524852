export const makeApiCall = async (url, method, body = null) => {
  try {
    if (method === "GET") {
      body = null;
    }
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: body ? JSON.stringify(body) : body,
    });
    const data = await response.json();
    if (!response.ok) {
      const message = `An error has occured: ${response.status} ${data.error}`;
      throw new Error(message);
    }
    return data;
  } catch (error) {
    console.error(error);
  }
};
