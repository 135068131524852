import React, { useEffect, useRef, useState } from "react";
import Post from "../components/Post";
import { Box } from "@mui/system";
import PostForm from "../components/PostForm";
import { makeApiCall } from "../utils/Apicall";
import { debounce } from "lodash";
import { API_URL } from "../constants";
import TransitionAlerts from "../components/TransitionAlerts";

const Feed = () => {
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 1 });
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    console.log("hello");
    console.log(hasMore);
  }, [hasMore]);

  useEffect(() => {
    const testUrl = `${API_URL}/feed/?page=${page}`;

    makeApiCall(testUrl, "get").then((res) => {
      console.log(res?.posts);
      if (res?.posts.length === 0) {
        console.log("no more posts");
        setHasMore(false);
        return;
      }
      setPosts((prevPosts) => [...prevPosts, ...(res?.posts || [])]);
    });
  }, [page]);

  const debouncedPageIncrement = debounce(
    () => setPage((prevPage) => prevPage + 1),
    3000
  );

  const handleObserver = (entities) => {
    const target = entities[0];
    if (hasMore && target.isIntersecting) {
      console.log(hasMore);
      debouncedPageIncrement();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.5rem",
        padding: "1rem",
      }}
    >
      <TransitionAlerts message="Introducing Feed Beta, enhancing our hybrid workshop for a more engaging experience!" />
      <PostForm />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "1rem",
        }}
      >
        {posts.map((post) => (
          <Post key={post._id} post={post} />
        ))}
        {hasMore ? (
          <div ref={loader}>Loading...</div>
        ) : (
          <div ref={loader}>No more posts</div>
        )}
        {/* <div ref={loader}>Loading...</div> */}
      </Box>
    </Box>
  );
};

export default Feed;
