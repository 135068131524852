import React from "react";
import { Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Element } from "react-scroll";
import PaymentTable from "./PaymentTable";
import { Link } from "react-router-dom";
import schdule from "../assets/schedule.webp";
const Section4 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        color: "#FFFFFF",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "auto",
          position: "relative",
          color: "#FFFFFF",
          padding: "2.5%",
          width: {
            xs: "95%",
            sm: "95%",
            md: "95%",
            lg: "55%",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#242B2E",
            borderRadius: "25px",
            color: "#FFFFFF",
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: "100%",
            },
            padding: "1% 2.5% 2.5% 2.5%",
          }}
        >
          <Element name="Schedule">
            <h1
              style={{
                textAlign: "center",
              }}
            >
              Schedule
            </h1>
          </Element>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
              margin: "auto",
              width: "100%",
              height: "75%",
            }}
          >
            {/* <h3
              style={{
                textAlign: "center",
                color: "#FFD700",
                marginTop: "10%",
                // center vertically
                // position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
              }}
            >

            </h3> */}
            <img
              src={schdule}
              alt="COMING SOON !!!"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "25px",
              }}
            />
          </Box>
        </Box>
      </Box>
       <Box
        sx={{
          color: "#FFFFFF",
          padding: "2.5%",
          width: {
            xs: "95%",
            sm: "95%",
            md: "95%",
            lg: "35%",
          },
          height: "auto",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#242B2E",
            color: "#FFFFFF",
            borderRadius: "25px",
            height: "auto",
            textAlign: "center",
            padding: "2.5% 2.5% 1% 2.5%",
          }}
        >
          <Element name="Register" id="Register">
            <h1> Thank You !!!</h1>
          </Element>
          <Box>

          
          </Box>
        </Box>
      </Box> 
    </Box>
  );
};

export default Section4;
