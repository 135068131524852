import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { Avatar, CircularProgress } from "@mui/material";
import { useState } from "react";
import { makeApiCall } from "../utils/Apicall";
import { API_URL } from "../constants";

const PostForm = () => {
  const [open, setOpen] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (event) => {
    if (event.target.textContent === "Post") {
      if (postContent.length == 0) {
        alert("Enter content for the post.");
        return;
      }
      setLoading(true);
      const url = `${API_URL}/feed`;
      const payload = {
        content: postContent,
      };
      const res = await makeApiCall(url, "post", payload);
      window.location.reload();
      console.log(res);
      setLoading(false);
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1.5rem",
          padding: "1rem",
          border: "1px solid #242B2E",
          borderRadius: "1rem",
          width: 345,
          boxSizing: "border-box",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "red",
            width: "3rem",
            height: "3rem",
          }}
        >
          K
        </Avatar>
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          sx={{
            width: "100%",
            borderRadius: "1rem",
          }}
        >
          Create Post
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Karthikeya</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Engage with your peers and share your thoughts. Have Questions? or
            Want to share something? Go ahead and post it here.
          </DialogContentText>
          <TextField
            autoFocus
            label="Post Content"
            margin="dense"
            multiline
            rows={4}
            fullWidth
            onChange={(e) => setPostContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {loading ? (
            <CircularProgress
              sx={{
                margin: "0.5rem",
                width: "2rem",
                height: "2rem",
              }}
            />
          ) : (
            <Button onClick={handleClose}>Post</Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PostForm;
