import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import { API_URL } from "../constants";
import speakersData from "../content/speakers.json";

const SpeakersSection = () => {
  const [academiaSpeakers, setAcademiaSpeakers] = useState([]);
  const [industrySpeakers, setIndustrySpeakers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}/speakers/6473a8d750e038bfe765ab70`)
      .then((response) => response.json())
      .then((data) => {
        const academiaData = data.speakers.filter(
          (item) => item.workingField === "Academia"
        );
        const industryData = data.speakers.filter(
          (item) => item.workingField === "Industry"
        );
        setAcademiaSpeakers(academiaData);
        setIndustrySpeakers(industryData);
        setLoading(false);
      })
      .catch((err) => {
        const academiaData = speakersData.speakers.filter(
          (item) => item.workingField === "Academia"
        );
        const industryData = speakersData.speakers.filter(
          (item) => item.workingField === "Industry"
        );
        setAcademiaSpeakers(academiaData);
        setIndustrySpeakers(industryData);
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ padding: "3.5%" }}>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Speakers
      </h2>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Academic Experts
          </h3>
          {loading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                overflowY: "auto",
                maxHeight: "400px",
                bgcolor: "#616C6F",
                borderRadius: "25px",
                marginTop: "5px",
                "&::-webkit-scrollbar": {
                  width: "0.6em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ffffff",
                  borderRadius: "25px",
                },
              }}
            >
              {academiaSpeakers.map((speaker, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ color: "white", textAlign: "center" }}>
                    <img
                      style={{
                        height: "150px",
                        width: "150px",
                        borderRadius: "50%",
                        margin: "auto",
                      }}
                      src={speaker.profilePicPublicUrl}
                      alt={speaker.name}
                    />
                    <a
                      href={speaker.websiteLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="h5" sx={{ color: "#f9adc0" }}>
                        {speaker.name}
                      </Typography>
                    </a>
                    <Typography variant="body1">
                      {speaker.designation} <br /> {speaker.company}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Box sx={{ width: "100%", textAlign: "center", marginTop: "3%" }}>
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Industry Experts
          </h3>
          {loading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                overflowY: "auto",
                maxHeight: "400px",
                bgcolor: "#616C6F",
                marginTop: "5px",
                borderRadius: "25px",
                "&::-webkit-scrollbar": {
                  width: "0.6em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ffffff",
                  borderRadius: "25px",
                },
              }}
            >
              {industrySpeakers.map((speaker, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ color: "white", textAlign: "center" }}>
                    <img
                      style={{
                        height: "150px",
                        width: "150px",
                        borderRadius: "50%",
                        margin: "auto",
                      }}
                      src={speaker.profilePicPublicUrl}
                      alt={speaker.name}
                    />
                    <a
                      href={speaker.websiteLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="h5" sx={{ color: "#f9adc0" }}>
                        {speaker.name}
                      </Typography>
                    </a>
                    <Typography variant="body1">
                      {speaker.designation} <br /> {speaker.company}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SpeakersSection;
